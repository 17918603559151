import  React from "react"

//COMPONENTS
import Layout from "../../components/layout"
import Seo from "@components/Seo.js"
import Breadcrumbs from "../../components/Breadcrumbs"

const PrivacyPage = () => {

    const pageData = {
        title: "Privacy Policy",
        description: `Privacy policy information related to the use of our essay and term paper writing service.`,
        keywords: `privacy essay, private writing, privacy policy`
    }

    const steps= [
        {
            name: 'Privacy',
            path: '/info/privacy'
        }
    ]

    return (
        <>
            <Seo {...pageData} />
            <Layout>
                <div className="info-intro">
                    <div className="wrapper">
                        <div className="info-intro--content">
                            <Breadcrumbs steps={steps}/>
                            <h1 className="page-intro__title">Privacy Policy</h1>
                        </div>                    
                    </div>
                </div>
                <section className="info">
                    <div className="wrapper">
                        <div className="row">
                            <div className="info-content">
                                <h3>I. INTRODUCTION</h3>
                                <p>
                                    We value, respect, and actively protect our clients’ privacy and confidentiality.
                                    This Privacy Policy is therefore intended to inform our clients regarding how we
                                    process data including how we collect,store, utilize,
                                    and safeguard such information. This Privacy Policy also informs our clients of
                                    their rights with regard to how their information is processed.
                                </p>
                                <p>
                                    “Client” refers to an individual who avails of our services through our website.
                                    “Personal Information” refers to information that could identify an individual.
                                    Personal Information may include the Client’s name, contact information such as the
                                    mobile number and email address, and billing details
                                    and financial information necessary for transactions among others.
                                    “Non-Identifying Information” refers to information that does not identify an individual
                                    such as zip code, demographic data, and order details.
                                </p>
                                <p>
                                    Collecting Personal Information and Non-Identifying Information from clients is an
                                    essential part of our daily business operations.
                                    We use this information in order to meet our clients’ needs as well as continually
                                    enhance the efficiency of our operations and the quality of our services.
                                </p>

                                <h4>I.1 Personal Information</h4>
                                <p>
                                    Collecting information from a Client through our website is necessary in order for
                                    us to effectively provide services. This information may include both Personal Information
                                    (e.g., name, email address, mobile number) and Non-Identifying Information (e.g. zip code and order details).
                                </p>

                                <h4>I.2 Payment Information</h4>
                                <p>
                                    We collect payment for our services through various payment services providers such as WorldPay and PayPal among others.
                                    While we collect and store information related to your payment such as the fees you pay for the services we provide, we
                                    do not collect and store Personal Information such as the name of the cardholder and other credit card data. Such information
                                    goes directly to payment services providers that have their respective privacy policies regarding the processing of such
                                    information. We, therefore, advise the Client to thoroughly review the privacy policies of such payment services providers.
                                </p>

                                <h4>I.3 Identity Verification</h4>
                                <p>
                                    There may be instances when we collect Personal Information for the purposes of validating your identity or adhering to legal
                                    requirements. Such instances may involve the requisition of documents such as billing statements or valid identification.
                                </p>

                                <h4>I.4 Non-Identification Information</h4>
                                <p>
                                    The processing of Non-Identifying Information such as zip codes, order details, and demographic data may also be conducted as
                                    part of our provision of services.
                                </p>

                                <h3>II. HOW WE USE PERSONAL INFORMATION</h3>
                                <p>
                                    The information we collect and store is utilized in the provision of services that our clients avail and pay for.
                                    Such information may be used for the following specific purposes:
                                </p>

                                <ol>
                                    <li>Providing the products and services the Client has availed;</li>
                                    <li>Providing services intended to meet the Client’s specific needs;</li>
                                    <li>Providing answers to the Client’s questions regarding our services;</li>
                                    <li>Responding to any concerns and complaints the Client may bring to our attention;</li>
                                    <li>Processing the Client’s orders and facilitating any related transactions;</li>
                                    <li>Performing research and data analysis intended to enhance our services;</li>
                                    <li>Complying with other duties and obligations as mandated by law;</li>
                                    <li>Any other purpose for which the Client has given consent.</li>
                                </ol>
                                <p>
                                    We will utilize Personal Information provided by the Client based on the following lawful grounds:
                                </p>
                                <ol>
                                    <li>When the Client has consented;</li>
                                    <li>Utilizing information is a requisite to upholding a contract to which the Client agreed;</li>
                                    <li>Complying with legal duties and obligations as mandated by law;</li>
                                    <li>Utilizing information for satisfying legitimate business interests;</li>
                                    <li>Utilizing the information in protecting the interests of a Client or another individual.</li>
                                </ol>

                                <h4>II.1 Accessing, Correcting, and Downloading Personal Information</h4>
                                <p>
                                    The Client has the right to know what Personal Information and Non-Identifying Information we collect, store,
                                    and utilize, except in cases where exceptions apply. Information will be made available to the Client at no cost.
                                    Information we process can be viewed on the Client’s account as well as downloaded by the Client upon request.
                                    The Client also has the right to correct or update information. Customer support services will be made available in cases
                                    where the Client requires assistance in accessing, downloading, correcting, or updating Personal Information.
                                </p>

                                <h4>II.2 Exercising Client’s Other Rights</h4>
                                <p>
                                    The client has other rights relevant to our processing of Client information, although there may be limitations to this right.
                                    Other rights the Client has included but are not limited to the following:
                                </p>
                                <ol>
                                    <li>Request for human review of automated processing of information;</li>
                                    <li>Choose to not participate in or be the recipient of direct marketing (e.g., updates, newsletters, etc.);</li>
                                    <li>Deletion of information;</li>
                                    <li>Temporary restriction of the processing.</li>
                                </ol>
                                <p>
                                    ***Deletion: Some Personal Information cannot be deleted from the system if they are essential to maintaining contracts,
                                    documenting transactions, and safeguarding other individuals’ rights. Non-Identifying Information that can be used alongside
                                    Personal Information to identify individuals can be deleted or anonymized as necessary.
                                </p>
                                <p>
                                    ***Temporary Restriction to Processing: There are instances the Client may request for temporary restriction to the processing
                                    of information. These include but are not limited to situations where the Client believes that the Personal Information is
                                    inaccurate or that there are no legal grounds for processing such information. The Client can contact us for such matters and
                                    relevant concerns.
                                </p>

                                <h3>III. DATA DELETION</h3>
                                <p>
                                    Our company is committed to protecting the privacy and security of your personal information.
                                    This Privacy Policy outlines our practices regarding the deletion of personal data.
                                </p>
                                <h4>III.1 Data Deletion Requests:</h4>
                                <p>
                                    If you wish to have your personal data deleted from our records, please submit a written request to support. In your request, please include the following information:

                                    Full name
                                    Contact information (email address, phone number, etc.)
                                    Description of the data you want to be deleted
                                </p>
                                <h4>III.2 Verification Process:</h4>
                                <p>
                                    To ensure the security of your personal information, we may need to verify your identity before processing a data deletion request.
                                    This verification process may involve requesting additional information from you.
                                </p>
                                <h4>III.3 Timeframe for Data Deletion:</h4>
                                <p>
                                    Once your identity is verified and your request is approved, we will make all reasonable efforts to delete your personal data within several days.
                                    Please note that some data may be retained for a certain period due to legal or regulatory requirements.
                                </p>
                                <h4>III.4 Exceptions to Data Deletion:</h4>
                                <p>
                                    In certain situations, we may be unable to fulfill your request for data deletion.
                                    These situations include, but are not limited to, cases where the data is necessary for legal compliance, contractual obligations, or to protect our legitimate interests.
                                </p>
                                <h4>III.5 Notification of Data Deletion:</h4>
                                <p>
                                    Upon successful deletion of your personal data, we will notify you via the contact information provided in your request.
                                </p>
                                <h3>IV. POLICY UPDATES</h3>
                                <p>
                                    This Privacy Policy is continually reviewed and updated in response to changes in laws and as well as part of upholding our commitment
                                    to safeguarding our clients’ privacy and confidentiality. The Client will be duly informed each time updates are made. Availing our services
                                    after updates have been made will be regarded as an indication of the Client’s consent to such updates. We, therefore, advise the Client
                                    to occasionally review this policy to remain informed. Prior versions of the policies will be made available to the Client.
                                </p>

                                <div className="info-content-buttons">
                                    <a href="https://shop.customessaymeister.com/writers" className="button button-secondary info-content-buttons-read">
                                        Read about our writers
                                    </a>

                                    <a href="https://shop.customessaymeister.com/order/new" className="button button-primary info-content-buttons-order">
                                        Order Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default PrivacyPage
